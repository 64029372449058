.float-label {
  position: relative;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 11px;
  transition: 0.2s ease all;
}

.as-placeholder {
  color: gray;
}

.as-label {
  top: -8px;
  z-index: 2;
  background: rgb(31, 31, 31);
  font-size: 12px !important;
  padding: 0 8px;
  margin-left: -4px;
}
