.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.d-none {
  display: none;
}

.ant-spin-dot-item {
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(155, 155, 155, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(155, 155, 155, 0.5);
  background: rgba(155, 155, 155, 0.5);
}